




































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";
import PhoneInput from "@/components/util/PhoneInput.vue";
import PersonService from "@/services/PersonService";
import {AccountType} from "@/constants/AccountTypes";
import CustomerAccounts from "@/dto/CustomerAccounts";
import PersonDTO from "@/dto/person/PersonDTO";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import {fromCompany, fromPerson} from "@/dto/auth/Workspace";
import CreateCompanyRequestDTO from "@/dto/company/CreateCompanyRequestDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import MergeCompanyEinModal from "@/components/profile/admin/company/MergeCompanyEinModal.vue";
import MergePersonSsnModal from "@/components/profile/admin/person/MergePersonSsnModal.vue";
import CompanyService from "@/services/CompanyService";
import PortalInput from "@/components/common/PortalInput.vue";
import {InputType} from "@/components/common/InputType";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {PortalCheckbox, PortalInput, PhoneInput},
    computed: {
		InputType() {
			return InputType
		},
        AccountType() {
            return AccountType
        }
    }
})
export default class MergeAccountModal extends Vue {
    private message = "";

    private successful = false;

	private tab: AccountType | null = null;

    @Prop({default: AccountType.PERSON})
    private mode!: AccountType;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private tabIndex = this.mode;

    @Prop({default: new CustomerAccounts()})
    private customerAccounts!: CustomerAccounts;

    @Prop({default: new CreatePersonRequestDTO()})
    private personReq!: CreatePersonRequestDTO;

    @Prop({default: new CreateCompanyRequestDTO()})
    private companyReq!: CreateCompanyRequestDTO;

    @Prop()
    private onSuccess!: () => void

	public mounted(): void {
		this.tab = this.mode;
	}

    openLinksModal(obj: PersonDTO | CompanyDTO) {
        this.$modal.show(
            AdminLinksModal,
            {
                workspace: obj instanceof PersonDTO ? fromPerson(obj as PersonDTO) : fromCompany(obj as CompanyDTO),
                name: obj instanceof PersonDTO ? obj.lastName?.concat(" ").concat(obj.firstName as string)
                    : obj.name
            }
        );
    }

    closeModal(){
        this.$modal.hideAll();
    }

    mergeCompany() {
       ifValid(this, () => {
		   this.startLoading()
		   this.message = ""
		   CompanyService.registerCompanyWithExistEmail(this.companyReq!).then(
			   () => this.handleSuccess(),
			   err => this.errorHandling(err)
		   )
	   })
    }

    selectCompany(company: CompanyDTO) {
        this.openLinksModal(company);
    }

    handleSuccess() {
        this.onSuccess();
        this.stopLoading();
        this.successful = true;
        this.closeModal();
    }

    errorHandling(err: any) {
        this.successful = false;
        let systemCode = err?.response?.data.systemCode;
        let param = err?.response?.data.param;
        let company: CompanyDTO;
        let person: PersonDTO;
        switch (systemCode) {
            case 40023:
                person = param;
                if (person.email == null && this.personReq!.email != null) {
                    this.openMergePersonSsnModal(person);
                } else {
                    this.openLinksModal(person);
                }
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
                    {ssn: person.ssn, lastName: person.lastName, firstName: person.firstName}) as string;
                break;
            case 40024:
                company = param;
                if (company.email == null && this.companyReq!.email != null) {
                    this.openMergeCompanyEinModal(company);
                } else {
                    this.openLinksModal(company);
                }
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), {name: company.name, ein: company.ein}) as string;
                break;
            case 40026:
                console.log(err.response.data)
                break;
            default:
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), param) as string;
        }
        this.stopLoading();
    }

    mergePerson() {
        ifValid(this, () => {
			this.startLoading()
			this.message = ""
			PersonService.registerPersonEntityOnExistedEmail(this.personReq!).then(
				() => this.handleSuccess(),
				err => this.errorHandling(err)
			)
		})
    }

    public maskHandleForEin() {
        let mask = "__-_______";
        let oldVal = this.personReq!.ssn!.replace(/\D/g, "");

        if (mask.length >= this.companyReq!.ein!.length) {
            this.companyReq!.ein = this.buildPhoneWithMask(mask, oldVal);
            if (this.companyReq!.ein!.substr(oldVal.length -1) == "-") {
                this.companyReq!.ein = oldVal.substr(0, oldVal.length);
            }
        } else if (mask.length < this.companyReq!.ein!.length){
            this.companyReq!.ein = this.companyReq!.ein!.substr(0, mask.length)
        }
    }

    public maskHandleForSsn() {
        let mask = "___-__-____";
        let oldVal = this.personReq!.ssn!.replace(/\D/g, "");

        if (mask.length >= this.personReq!.ssn!.length) {
            this.personReq!.ssn = this.buildPhoneWithMask(mask, oldVal);
            if (this.personReq!.ssn!.substr(oldVal.length -1) == "-") {
                this.personReq!.ssn = oldVal.substr(0, oldVal.length);
            }
        } else if (mask.length < this.personReq!.ssn!.length){
            this.personReq!.ssn = this.personReq!.ssn!.substr(0, mask.length)
        }
    }

    private buildPhoneWithMask(mask: string, oldVal: string) {
        let i = 0;

        let newValue = this.deleteSuffixIfNeed(mask.replace(/[_\d]/g, function (a:string) {
            return i < oldVal.length ? oldVal.charAt(i++) : a
        }));

        if (newValue.length <= mask.length) {
            oldVal = newValue;
        }

        return newValue;
    }

    private deleteSuffixIfNeed(newValue: string) {
        if  (newValue.indexOf("_") != -1) {
            newValue = newValue.substr(0, newValue.indexOf("_"))
        }

        if (newValue.substr(newValue.length - 1) == "-") {
            newValue = newValue.substr(0, newValue.length - 1);
        }

        let suffix = newValue.substr(newValue.length - 2);

        if (suffix == " (" || suffix == ") ") {
            newValue = newValue.substr(0, newValue.length - 2)
        }

        return newValue;
    }

    get isPhoneFilledPerson(): boolean{
        return (this.personReq!.phone != undefined || this.personReq!.phone != null) && this.personReq!.phone != "" && this.personReq!.phone.length >= 7;
    }

    get isPhoneFilledCompany(){
        return (this.companyReq!.phone != undefined || this.companyReq!.phone != null) && this.companyReq!.phone != "" && this.companyReq!.phone.length >= 7;
    }

    openMergeCompanyEinModal(company: CompanyDTO) {
        this.$modal.show(
            MergeCompanyEinModal,
            {
                existCompany: company,
                request: this.companyReq,
                onSuccess: () => this.handleSuccess()
            }
        )
    }

    openMergePersonSsnModal(existPerson: PersonDTO) {
        this.$modal.show(
            MergePersonSsnModal,
            {
                request: this.personReq,
                existPerson: existPerson,
                onSuccess: () => this.handleSuccess()
            }
        );
    }
}
